<template>
  <div class="mb-3 card">
    <div>
      <v-tabs v-model="tab">
        <v-tab key="list">
          {{ $t('erp.lang_nav_shelflabels') }}
        </v-tab>
        <v-tab key="settings">
          {{ $t('settings.lang_settings') }}
        </v-tab>

        <v-tabs-items v-model="tab">
          <v-tab-item key="list">
            <v-container fluid>
              <v-layout>
                <v-flex md12 align-self-center>
                  <label>{{ $t("generic.lang_formSelectMakeASelection") }}</label>
                </v-flex>
                <v-flex>
                  <v-btn-toggle class="mb-1" active-class="success" v-model="size">
                    <v-btn :value="0" class="ma-0">
                      {{ $t('generic.lang_small') }}
                    </v-btn>

                    <v-btn :value="1" class="ma-0">
                      {{ $t('generic.lang_medium') }}
                    </v-btn>

                    <v-btn :value="2" class="ma-0">
                      {{ $t('generic.lang_large') }}
                    </v-btn>

                    <v-btn :value="3" class="ma-0">
                      Legacy
                    </v-btn>
                  </v-btn-toggle>
                </v-flex>
              </v-layout>
              <v-layout>
                <v-flex md12>
                  <div :class="[this.$vuetify.theme.dark? 'dark-bg' : '']" class="card-header " style="overflow-y: auto;"
                       v-if="selectedLabels && selectedLabels != null && selectedLabels.length > 0">
                    <v-chip style="min-width: 300px;" class="mx-1"
                            v-for="(label, index) in selectedLabels"
                            :key="'l-'+index"
                            v-model="selectedLabels[index].selected">
                      {{ label.name }}
                    </v-chip>
                  </div>
                  <div v-else :class="[this.$vuetify.theme.dark? 'dark-bg' : '']" class="card-header">
                    {{ $t("erp.lang_nolabelselected") }}
                  </div>
                </v-flex>
              </v-layout>
              <v-layout>
                <v-flex md6 mr-2 ml-2>
                  <v-btn block text color="error" @click="resetSelected">{{ $t('generic.lang_reset') }}
                  </v-btn>
                </v-flex>
                <v-flex md6 mr-2 ml-2>
                  <v-btn block color="success" @click="showPdfDialog=true"
                         :disabled="!(selectedLabels&&selectedLabels.length>0)">{{
                      $t('erp.lang_nav_shelflabels')
                    }}
                  </v-btn>
                </v-flex>
              </v-layout>
              <v-layout>
                <v-flex md12>
                  <!-- Table -->
                  <Datatable ref="taxSetupDatatable"
                             :api-endpoint="ENDPOINTS.DATATABLES.ERP.PRINTSHELFLABEL"
                             :datatable-headers="datatableHeaders"
                             :excel-columns="excelColumns"
                             excel-file-name="PrintShelfLabels"
                             :show-select-checkbox="true"
                             :noExcelExport="false" @changeSelectedRows="selectRows" :reset-selected="false"
                  />
                </v-flex>
              </v-layout>
            </v-container>
          </v-tab-item>

          <v-tab-item key="settings">
            <v-container fluid>
              <v-layout>
                <v-flex>
                  <v-checkbox
                    :true-value="1" :false-value="0"
                    :label="$t('erp.lang_displayPricesWithoutTax')"
                    v-model="displayPricesWithoutTax"
                  ></v-checkbox>
                </v-flex>
              </v-layout>

              <v-layout>
                <v-flex class="mt-5 justify-end text-right">
                  <v-btn @click="updateDefaultSettings" color="success" large> {{$t('generic.lang_save')}} </v-btn>
                </v-flex>
              </v-layout>
            </v-container>
          </v-tab-item>
        </v-tabs-items>
      </v-tabs>
    </div>

    <PDFPreviewDialog :drawer="showPdfDialog" @close="showPdfDialog=false" :api="endpoint"
                      :data="JSON.stringify(ids)"/>
    <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
      <vue-touch-keyboard class="internalWidth" id="onScreenKeyboard" :options="touchKeyboard.options"
                          :defaultKeySet="touchKeyboard.keySet" v-if="touchKeyboard.visible"
                          :layout="touchKeyboard.layout" :cancel="hideTouchKeyboard"
                          :accept="hideTouchKeyboard" :input="touchKeyboard.input"/>
    </div>
  </div>
</template>

<style>
.dataTables_filter {
  display: none;
}
label.v-label {
  margin-bottom: 0rem;
}
</style>

<script>
import {ENDPOINTS} from '../../../config'
import {Events} from "../../../plugins/events";
import Datatable from "../../datatable/Datatable";
import mixin from "../../../mixins/KeyboardMixIns";
import {mapState} from "vuex";
import PDFPreviewDialog from "@/components/common/PDFPreviewDialog";

export default {
  components: {
    PDFPreviewDialog,
    Datatable
  },

  mixins: [mixin],

  data() {
    return {
      ENDPOINTS,
      search: "",
      endpoint: ENDPOINTS.ERP.PRINTSHELFLABEL.PRINTSMALL,
      loading: false,
      tab: 0,
      displayPricesWithoutTax: 0,
      size: 0,//0 small, 1 medium, 2 large, 3 legacy
      selectedLabels: [],
      showPdfDialog: false,
      // --- Datatable ---
      datatableHeaders: [
        {
          text: 'ID',
          align: 'left',
          value: 'id',
          width: 80
        },
        {text: this.$t('erp.lang_ware_create_id'), value: "wareID"},
        {text: this.$t('erp.lang_itemname'), value: "wareName"},
        {text: this.$t('erp.internal_wareNo'), value: "wareInternalNo"}
      ],
      excelColumns: [

        {
          label: "ID",
          field: 'id',
        },
        {
          label: this.$t('erp.lang_ware_create_id'),
          field: 'wareID',
        },
        {
          label: this.$t('erp.lang_itemname'),
          field: 'wareName',
        },
        {
          label: this.$t('erp.internal_wareNo'),
          field: 'wareInternalNo',
        }
      ],
    }
  },

  watch: {
    search: function () {
      this.dataTable.search(this.search).draw();
    },
    size(v) {
      switch (v) {
        case 0:
          this.endpoint = ENDPOINTS.ERP.PRINTSHELFLABEL.PRINTSMALL;
          break;
        case 1:
          this.endpoint = ENDPOINTS.ERP.PRINTSHELFLABEL.PRINTMEDIUM;
          break;
        case 2:
          this.endpoint = ENDPOINTS.ERP.PRINTSHELFLABEL.PRINTLARGE;
          break;
        case 3:
          this.endpoint = ENDPOINTS.ERP.PRINTSHELFLABEL.PRINTLEGACY;
          break;
      }
    }
  },

  computed: {
    ...mapState([
      'api'
    ]),
    ids() {
      let ids = [];
      if (!(this.selectedLabels && this.selectedLabels.length > 0)) {
        return [];
      }

      for (let i = 0; i < this.selectedLabels.length; i++) {
        if (this.selectedLabels[i].selected) {
          ids.push(this.selectedLabels[i].id);
        }
      }

      if (!(ids && ids != null && ids.length > 0)) {
        return [];
      }
      return ids;
    }
  },

  mounted() {
    this.getDefaultSettings();
  },

  methods: {
    getDefaultSettings() {
      this.loading = true;

      this.axios.post(ENDPOINTS.ERP.PRINTSHELFLABEL.SETTINGS.GET).then(res => {
        if (res.status === 200) {
          this.displayPricesWithoutTax = Number(res.data.displayPricesWithoutTax);
        } else {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).catch(err => {
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_errorOccurred'),
          color: "error"
        });
      }).finally(() => {
        this.loading = false;
      })
    },
    updateDefaultSettings() {
      this.loading = true;

      this.axios.post(ENDPOINTS.ERP.PRINTSHELFLABEL.SETTINGS.UPDATE, {
        displayPricesWithoutTax: this.displayPricesWithoutTax,
      }).then(res => {
        if (res.data.STATUS === 'SUCCESS') {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_success'),
            color: "success"
          });
        } else {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).catch(err => {
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_errorOccurred'),
          color: "error"
        });
      }).finally(() => {
        this.loading = false;
      })
    },
    printData: function () {
      let ids = [];

      let bs = this.api.auth.posID;
      let apiToken = this.api.auth.apiWebToken;
      let bsID = this.api.auth.accessString;

      if (!(this.selectedLabels && this.selectedLabels.length > 0)) {
        return;
      }

      for (let i = 0; i < this.selectedLabels.length; i++) {
        if (this.selectedLabels[i].selected) {
          ids.push(this.selectedLabels[i].id);
        }
      }

      if (!(ids && ids != null && ids.length > 0)) {
        return;
      }
      let apiServerIP = process.env.VUE_APP_API_HOST;
      if (window.location.host != 'localhost' && window.location.host != 'devkasse.3pos.de' && window.location.host != 'kasse.3pos.de') {
        let apiServerIP = location.protocol + "//" + window.location.hostname + ":8010/pos";
      }
      window.location = apiServerIP + "/" + ENDPOINTS.ERP.PRINTSHELFLABEL.PRINT + "?labels=" + JSON.stringify(ids) + "&bs=" + bs + "&apiToken=" + apiToken + "&bsid=" + bsID;

      this.selectedLabels = [];
    },
    rowClicked(entry) {
      //CHECK IF LABEL ALREADY IN SELECTION
      let label = this.selectedLabels.find((label) => {
        return label.id === parseInt(entry.id);
      });

      if (label)
        return;

      this.selectedLabels.push({
        id: parseInt(entry.id),
        selected: true,
        name: entry.wareName
      });
    },
    removeSelectedLabel(label, labelIndex) {
      this.$delete(this.selectedLabels, labelIndex);
    },
    resetSelected() {

      this.$refs.taxSetupDatatable.resetSelectedRows();
    },
    async selectRows(data) {
      let labels = data.map(label => {
        return {
          id: parseInt(label.id),
          selected: true,
          name: label.wareName,
        }
      })
      this.selectedLabels = labels;

    }
  },
}
</script>